import data from '../data/dat.json';
import axios from 'axios'


export const checkAuth = async () => {

    const token = localStorage.getItem('token');

    const res = await axios.get(data.url + '/api/getToken/' + token);

    if (token) {
        if (!res.data.length > 0) {
            localStorage.removeItem('token');

            console.log('remove');
        }
        else {
            console.log("auth ok");
        }
    }
}

