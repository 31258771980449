import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import differenceBy from 'lodash/differenceBy';
import { getFiles, getImage, deleteFileDB, addFileTagLink, getTagLinkFile, updateStatus, getUser } from '../../data/dataFiles'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import axios from 'axios'
import { DefaultButtons, PrimaryButton, SecondaryButton, SuccessButton, InfoButton, WarningButton, DangerButton, LightButton, SmallButtons, Add, Disabled, Active, LargeButtons, OutlineButtons, OutlineLargeButtons, OutlineSmallButtons, ActiveButtons, DisabledButtons, GradienButtons, ExtraSmallButtons, CustomStateButtons, BoldBorderOutlineButtons, OutlineExtraSmallButtons, DisabledOutlineButtons } from "../../constant";
import { addDBTag } from '../../data/dataDocument'
import Files from 'react-files';
import adata from '../../data/dat.json';

const AFiles = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState([]);
  const [dataTags, setDataTags] = useState([]);
  const [tag, setTag] = useState('');
  const [dataRaw, setDataRaw] = useState([]);
  const [modal1, setModal1] = useState();
  const [modal2, setModal2] = useState();
  const [files, setFiles] = useState([]);
  const [description, setDescr] = useState("");
  const [descriptionM, setDescrM] = useState("");
  const [name, setName] = useState("");
  const [nameM, setNameM] = useState("");
  const [id, setId] = useState(0);
  const [user, setUser] = useState({d_right : 0});



  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('4');

  const radios = [
    { name: 'En attente', value: '1' },
    { name: 'Validé', value: '2' },
    { name: 'Assigné', value: '3' },
    { name: 'Tout', value: '4' },
  ];

  function deleteFile(e) {
    setFiles([]);
    return files

  }
  const onFilesChange = (files) => {
    setFiles(files)
  }
  const onFilesError = (error, file) => {
    setFiles(file)
  }

  useEffect(() => {

    const dataFetch = async () => {
      const f = await getFiles();
      const us = await getUser();


  
      console.log(us);
      setData(f);
      setDataRaw(f);
      setUser(us);
      
    }
    // setData(f.values);
    dataFetch();


  }, []);

  const handleChangeTag = (e) => {
    setTag(e.target.value);
  };

  const handleDeleteTag = (state) => {



  };

  const addTagN = () => {


    console.log(tag);



    var to = dataTags.slice();


    var vtag = {
      d_name: tag
    }

    to.push(vtag);

    console.log(to);


    setDataTags(to);
    setTag('');


  };

  const addTag = () => {

    var tags = {
      name: tag,
    }



    const dataFetch = async () => {

      const i = await addDBTag(tags);
      console.log('i' + i);


      var t = {
        idFile: id,
        idTag: i
      }

      const l = await addFileTagLink(t);

      const ta = await getTagLinkFile(id);

      //setDataLink(l);
      //setDataFile(f);
      setDataTags(ta);

    };

    dataFetch();




  };


  const handleChangeTagN = (e) => {
    setTag(e.target.value);
  };

  const toggle1 = () => {

    setName("");
    setDescr("");
    setFiles([]);

    setModal1(!modal1)
  }

  const tagCol = [
    {
      name: 'Tag',
      selector: (row) => row.d_name,
      sortable: true,
      center: true,
      compact: true,
      grow: 1
    },
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={() => handleDeleteTag(row)} className='btn btn-danger'><i className="fa fa-trash-o"></i></span>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "100px",
      button: true,
      compact: true
    }
  ];



  const toggle2 = () => {
    setModal2(!modal2)
  }


  const saveFile = async () => {

    const tname = files[0].name;


    const nouveauNom = Date.now() + '.' + files[0].extension;

    const nouveauFichier = new File([files[0]], nouveauNom);


    if (name && files.length > 0) {

      var file = {
        orga: 1,
        name: name,
        descr: description,
        type: files[0].extension,
        path: nouveauFichier.name
      }


      axios.post(adata.url + '/api/addFile', file)
        .then(response => {


          setName("");
          setDescr("");
          deleteFile(files);

          console.log(response.data[0].id)

          for (var i = 0; i < dataTags.length; i++) {


            var tags = {
              name: dataTags[i].d_name,
            }



            const dataFetch = async () => {

              const i = await addDBTag(tags);
              console.log('i' + i);


              var t = {
                idFile: response.data[0].id,
                idTag: i
              }

              const l = await addFileTagLink(t);

              const ta = await getTagLinkFile(id);

              //setDataLink(l);
              //setDataFile(f);
              setDataTags(ta);

            };
            dataFetch();


          }







          setTimeout(() => {
            toast.success("Fichier ajouté avec succès");
          }, 200);

        })
        .catch(error => {
          console.log(error);

        });

      try {
        const formData = new FormData();

        formData.append('file', nouveauFichier); // Ajoutez le fichier au FormData






        const response = await axios.post(adata.url + '/api/uploadFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // N'oubliez pas de définir le type de contenu approprié
          },
        });

        console.log('Réponse du serveur :', response.data);
      } catch (error) {
        console.error('Erreur lors de l\'envoi du fichier :', error);
      }







    }
    else {
      setTimeout(() => {
        toast.error("Certains éléments sont manquants");
      }, 200);
    }
  }


  const saveModif = () => {


    console.log(nameM + descriptionM);


    if (nameM) {
      console.log('non null');

      var fil = {
        id: id,
        name: nameM,
        descr: descriptionM,
      }

      axios.post(adata.url + '/api/updFile', fil)
        .then(response => {

          console.log("ok");


          for (var i = 0; i < data.length; i++) {
            if (data[i].Id_t_file == id) {
              data[i].d_name = nameM;
              data[i].d_description = descriptionM;
            }
          }

          for (var i = 0; i < dataRaw.length; i++) {
            if (dataRaw[i].Id_t_file == id) {
              dataRaw[i].d_name = nameM;
              dataRaw[i].d_description = descriptionM;
            }
          }






          setModal2(!modal2)


          setTimeout(() => {
            toast.success("Fichier modifié avec succès");
          }, 200);

        })
        .catch(error => {
          console.log(error);

        });


    }






  }


  const columns = [
    {
      name: 'ID',
      selector: (row) => row.Id_t_file,
      sortable: true,
      center: true,
      width: "80px"
    },
    {
      name: 'Nom',
      selector: (row) => row.d_name,
      sortable: true,
      center: false,
      width: "200px"
    },
    {
      name: 'Description',
      selector: (row) => row.d_description,
      sortable: false,
      center: false,
      wrap: true
    },
    {
      name: 'Etat',
      selector: (row) => row.d_state,
      sortable: true,
      center: true,
      width: "120px"
    },
    {
      name: 'Date',
      selector: (row) => row.d_creation,
      sortable: true,
      center: true,
      width: "120px"
    },
    {
      name: 'Type',
      selector: (row) => row.d_type,
      sortable: true,
      center: true,
      width: "120px"
    },
    {
      name: "",
      cell: (row) => (

        <>
          <div className="btn-group btn-group-square" role="group" aria-label="Basic example">

            <span onClick={() => handleButtonDownload(row)} className='btn btn-primary btn-square'><i className="fa fa-download"></i></span>
            {user.d_right == 1 && (
              <span onClick={() => handleButtonValidate(row)} className='btn btn-success btn-square'><i className="fa fa-check"></i></span>
            )}
            <span onClick={() => handleButtonMod(row)} className='btn btn-info btn-square'><i className="fa fa-edit"></i></span>
            {user.d_right == 1 && (
              <span onClick={() => handleButtonDelete(row)} className='btn btn-danger btn-square'><i className="fa fa-trash "></i></span>
            )}
          </div>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "300px",
      button: true,
    }
  ];





  const handleButtonClick = (state) => {
    console.log('voir');
  };

  const handleButtonDownload = (state) => {

    console.log(state.d_name, state.d_type);

    getImage(state.d_path, state.d_type);
  };


  const handleButtonValidate = (state) => {


    console.log(state.d_state);

    if (state.d_state == 'En attente') {
      var file = {
        idFile: state.Id_t_file,
        status: 1,
      }

      axios.post(adata.url + '/api/ChangeFileStatus', file)
        .then(response => {



          setTimeout(() => {
            toast.success("Status changé avec succès.");
          }, 200);

        })
        .catch(error => {
          console.log(error);

        });

    }


  };


  const handleButtonMod = (state) => {
    setNameM(state.d_name);
    setDescrM(state.d_description);
    setId(state.Id_t_file);




    const dataFetch = async () => {

      const ta = await getTagLinkFile(state.Id_t_file);

      //setDataLink(l);
      //setDataFile(f);
      setDataTags(ta);

    };

    dataFetch();







    setModal2(!modal2)







  };

  const handleButtonDelete = (state) => {

    if (window.confirm('Voulez-vous vraiment supprimer ce fichier ? Il sera également supprimé des documents dans lesquels il est présent.')) {

      var id = {
        idFile: state.Id_t_file,
      }



      const f = deleteFileDB(id);

      console.log(state.Id_t_file);


      setToggleCleared(!toggleCleared);
      //  setData(differenceBy(data, state.Id_t_file, 'Id_t_file'));


      toast.success("Fichier supprimé avec succes !")
    }
  };


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);

        setData(differenceBy(data, selectedRows, 'name'));



        toast.success("Successfully Deleted !")
      }
    };

    return <button key="delete" onClick={handleDelete}>{"Delete"}</button>;
  }, [data, selectedRows, toggleCleared]);


  const updateTable = (value) => {

    console.log(value);
    setRadioValue(value);

    var da = [];

    if (value == 1) {

      console.log("cas 1");
      for (var i = 0; i < dataRaw.length; i++) {
        if (dataRaw[i].d_state == "En attente") {
          da.push(dataRaw[i]);
        }
      }
      setData(da);
    }
    else if (value == 2) {

      for (var i = 0; i < dataRaw.length; i++) {
        if (dataRaw[i].d_state == "Validé") {
          da.push(dataRaw[i]);
        }
      }
      setData(da);
    }
    else if (value == 3) {
      for (var i = 0; i < dataRaw.length; i++) {
        if (dataRaw[i].d_state == "Assigné") {
          da.push(dataRaw[i]);
        }
      }
      setData(da);
    }
    else if (value == 4) {
      console.log('cas 4');
      setData(dataRaw);
    }
  }


  // console.log(da);
  const handleChange = (e) => {
    setDescr(e.target.value);
  };

  const handleChangeN = (e) => {
    setName(e.target.value);
  };

  const handleChangeM = (e) => {
    setDescrM(e.target.value);
  };

  const handleChangeNM = (e) => {
    setNameM(e.target.value);
  };


  return (
    <Fragment>
      <Breadcrumb title="Fichiers" parent="Commune de braives" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">

              <div className="card-body datatable-react">
                <div className="btn-showcase">

                  <button className="btn btn-square btn-success float-end" type="button" onClick={toggle1}>Nouveau <i className="fa fa-plus"></i></button>
                </div>


                <ButtonGroup>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}

                      onChange={(e) => updateTable(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>



                <DataTable
                  columns={columns}
                  data={data}
                  striped={true}
                  center={true}


                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />


                <Modal isOpen={modal1} toggle={toggle1}>
                  <ModalHeader toggle={toggle1}>Nouveau fichier</ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <form className="form theme-form">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label htmlFor="exampleFormControlInput1">Nom</label>
                                      <input className="form-control" value={name} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeN} />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputPassword2">Description</label>
                                      <textarea value={description} className="form-control" id="exampleFormControlTextarea4" rows="3" onChange={handleChange}></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>

                            <div className="col-sm-5">
                              <DataTable
                                columns={tagCol}
                                data={dataTags}
                                striped={true}
                                center={true}
                                pagination={false}

                                persistTableHead
                                contextActions={contextActions}
                                onSelectedRowsChange={handleRowSelected}
                                clearSelectedRows={toggleCleared}
                                dense
                              />

                              <div className="row">
                                <div className="col-sm-8">
                                  <input className="form-control" value={tag} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeTagN} />
                                </div>
                                <div className="col-sm-4">
                                  <button className="btn btn-square btn-success float-end" type="button" onClick={addTagN}><i className="fa fa-plus-square"></i></button>
                                </div>
                              </div>

                            </div>



                            <Files
                              className='files-dropzone fileContainer'
                              onChange={onFilesChange}
                              onError={onFilesError}
                              accepts={['image/*', '.pdf']}
                              multiple={false}
                              maxFileSize={100000000}
                              minFileSize={0}
                              clickable
                            >
                              {
                                files.length > 0
                                  ? <div className='files-gallery'>
                                    {files.map((file, index) =>
                                      <img className='files-gallery-item' alt={file.name} src={file.preview.url} key={index} />
                                    )}
                                  </div>

                                  : <div className="d-flex justify-content-center">
                                    <button className="chooseFileButton me-2">Téléverser fichier</button>
                                  </div>
                              }
                            </Files>
                            {files.length > 0 ?
                              <div className="d-flex justify-content-center">
                                <button className="btn btn-primary mt-2" type="button" onClick={() => deleteFile(files)}>
                                  Supprimer
                                </button></div> : ''}
                          </div>
                        </div>
                      </div>
                    </div>

                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={saveFile}>Enregistrer</Button>{' '}
                    <Button color="secondary" onClick={toggle1}>Annuler</Button>
                  </ModalFooter>
                </Modal>






                <Modal isOpen={modal2} toggle={toggle2}>
                  <ModalHeader toggle={toggle2}>Modifier fichier {id}</ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <form className="form theme-form">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label htmlFor="exampleFormControlInput1">Nom</label>
                                      <input className="form-control" value={nameM} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeNM} />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label htmlFor="exampleInputPassword2">Description</label>
                                      <textarea value={descriptionM} className="form-control" id="exampleFormControlTextarea4" rows="3" onChange={handleChangeM}></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-5">
                                    <DataTable
                                      columns={tagCol}
                                      data={dataTags}
                                      striped={true}
                                      center={true}
                                      pagination={false}

                                      persistTableHead
                                      contextActions={contextActions}
                                      onSelectedRowsChange={handleRowSelected}
                                      clearSelectedRows={toggleCleared}
                                      dense={true}
                                    />

                                    <div className="row">
                                      <div className="col-sm-8">
                                        <input className="form-control" value={tag} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeTag} />
                                      </div>
                                      <div className="col-sm-4">
                                        <button className="btn btn-success float-end" type="button" onClick={addTag}><i className="fa fa-plus-square"></i></button>
                                      </div>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                    </div>

                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={saveModif}>Enregistrer</Button>{' '}
                    <Button color="secondary" onClick={toggle2}>Annuler</Button>
                  </ModalFooter>
                </Modal>








              </div>
            </div>
          </div>


        </div>
      </div>
    </Fragment>
  );
}
export default AFiles;