import React, { Fragment, useState } from 'react';
import {English,Spanish,Portuguese,French} from '../../../constant'
import { useTranslation } from "react-i18next";

const Language = () => {
    const { i18n } = useTranslation();
    const [selected,setSelected] = useState('fr');
    
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        setSelected(lng)
      };

    return (
        <Fragment>
            <div>
            <a className="txt-dark" href="#javascript">
                  <h6 className='text-uppercase'>{selected}</h6></a>
                <ul className="language-dropdown onhover-show-div p-20">
                    <li onClick={() => changeLanguage('fr')}>
                        <a href="#javascript" data-lng="fr">
                            <i className="flag-icon flag-icon-fr"></i> Français
                        </a>
                    </li>
                    <li onClick={() => changeLanguage('nl')}>
                        <a href="#javascript" data-lng="pt">
                            <i className="flag-icon flag-icon-nl"></i> Néérlandais
                        </a>
                    </li>
                </ul>
            </div>
        </Fragment>
    );
};


export default Language;