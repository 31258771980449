import data from '../data/dat.json';
import axios from 'axios'
import SimpleDateTime from 'react-simple-timestamp-to-date';

export async function getDocuments(response) {

    const res = await axios.get(data.url + '/api/getDoc/' + '1');

    console.log("get doc");

    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

    }

    return res.data;

}


export async function getDocLink(id) {

    console.log(id);

    const res = await axios.get(data.url + '/api/getDocLink/' + id);

    // console.log(res.data);

    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);


    }



    return res.data;

}

export async function getTagLink(id) {

    console.log(id);

    const res = await axios.get(data.url + '/api/getTagLink/' + id);

    // console.log(res.data);


    return res.data;

}

export async function getCatLink(id) {

    console.log(id);

    const res = await axios.get(data.url + '/api/getCategorieLink/' + id);

    // console.log(res.data);


    return res.data;

}

export async function getCategorie() {



    const res = await axios.get(data.url + '/api/getCat/' + 1);

    // console.log(res.data);


    return res.data;

}

export async function getChildCategorie(parent) {


    const res = await axios.get(data.url + '/api/getChildCat/' + 1 + '/' + parent);


    return res.data;

}


export async function addDocLink(link) {


    const res = await axios.post(data.url + '/api/addDocLink', link);

    console.log(res);

    return res
}

export async function deleteDocument(id) {


    const res = await axios.post(data.url + '/api/deleteDocument', id);


    return res
}

export async function addDBTag(tag) {


    const res = await axios.post(data.url + '/api/addTag', tag);

    console.log(res);

    return res.data.newId
}

export async function addDocTagLink(tagLink) {


    const res = await axios.post(data.url + '/api/addDocTagLink', tagLink);

    console.log(res);

    return res
}

export async function addDocCatLink(catLink) {


    const res = await axios.post(data.url + '/api/addDocCategorieLink', catLink);

    console.log(res);

    return res
}

export async function addDBDoc(doc, dataLink, catVList, dataTags) {


    
    const res = await axios.post(data.url + '/api/addDBDoc', doc);



    console.log(res.status);

    if (res.status == 200) {


        for (var i = 0; i < dataLink.length; i++) {

            var link = {
                idDoc: res.data.newId,
                idFile: dataLink[i].Id_t_file,
            }

            const o = await addDocLink(link);

        }

console.log(catVList);

        catVList.map((catItem) => {
            catItem.catChild.map((child) => {
                var cat = {
                    idDoc: res.data.newId,
                    idCat: child.Id_t_categorie
                }

                if (child.isChecked) {
                    const a = addDocCatLink(cat);
                }

            })
        }
        );




        dataTags.map(async (tag) => {

            var tags = {
                name: tag.d_name,
            }

            console.log("taaaaaaaaaaaaaaaaaaaaaaaaag " + tags);


            const reso = await axios.post(data.url + '/api/addTag', tags);



            var t = {
                idDoc: res.data.newId,
                idTag: reso.data.newId
            }

            const l = await addDocTagLink(t);

        });








    }

    return res.data.newId
}

export async function removeDocLink(link) {


    const res = await axios.post(data.url + '/api/removeDocLink', link);

    console.log(res)

    return res
}

export async function deleteTag(id) {


    const res = await axios.post(data.url + '/api/deleteTag', id);



    return res
}

export async function deleteCatLink(link) {


    const res = await axios.post(data.url + '/api/deleteCatLink', link);



    return res
}







