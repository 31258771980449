import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import DataTable from 'react-data-table-component'
import { getWaitingFiles, getFiles, getRelativeFiles, searchRelativeFile} from '../../data/dataFiles'
import { getDocuments, getDocLink, addDocLink, removeDocLink, addDBDoc, addDBTag, addDocTagLink, getTagLink, deleteTag, getCategorie, addDocCatLink, getCatLink, deleteCatLink, getChildCategorie, deleteDocument } from '../../data/dataDocument'
import axios from 'axios'
import adata from '../../data/dat.json';

const Documents = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [dataFile, setDataFile] = useState([]);
  const [dataFileFiltered, setDataFileFiltered] = useState([]);
  const [dataLink, setDataLink] = useState([]);
  const [dataDoc, setDataDoc] = useState([]);
  const [dataTags, setDataTags] = useState([]);
  const [tag, setTag] = useState('');
  const [category, setCategory] = useState([]);
  const [categoryLink, setCategoryLink] = useState([]);
  const [catVList, setcatVList] = useState([]);
  const [modal1, setModal1] = useState();
  const [modal2, setModal2] = useState();
  const [docName, setdocName] = useState("");
  const [docYear, setdocYear] = useState();
  const [searchText, setSearchText] = useState("");
  const [docText, setdocText] = useState("");
  const [curDoc, setCurDoc] = useState(0);
  const [forceRender, setForceRender] = useState(false);
  const [radioValue, setRadioValue] = useState('4');


  const columnsup = [
    {
      name: 'Id',
      selector: (row) => row.Id_t_file,
      sortable: true,
      center: true,
      width: "70px"
    },
    {
      name: 'Nom',
      selector: (row) => row.d_name,
      sortable: true
    },
    {
      name: 'Date',
      selector: (row) => row.d_creation,
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={() => removeFile(row)} className='btn btn-primary'><i className="fa fa-arrow-down"></i></span>


        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "100px",
      button: true
    }
  ];

  const columnsupNew = [
    {
      name: 'Id',
      selector: (row) => row.Id_t_file,
      sortable: true,
      center: true,
      width: "70px"
    },
    {
      name: 'Nom',
      selector: (row) => row.d_name,
      sortable: true
    },
    {
      name: 'Date',
      selector: (row) => row.d_creation,
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={() => removeFileNew(row)} className='btn btn-primary'><i className="fa fa-arrow-down"></i></span>


        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "100px",
      button: true
    }
  ];

  const radios = [
    { name: 'En attente', value: '1' },
    { name: 'Validé', value: '2' },
    { name: 'Assigné', value: '3' },
    { name: 'Tout', value: '4' },
  ];

  const updateTable = (value) => {

    console.log(value);
    setRadioValue(value);

    var da = [];


    if (value == 1) {

      console.log("cas 1");
      for (var i = 0; i < dataFile.length; i++) {
        if (dataFile[i].d_state == 0) {
          da.push(dataFile[i]);
        }
      }
      setDataFileFiltered(da);
    }
    else if (value == 2) {

      for (var i = 0; i < dataFile.length; i++) {
        if (dataFile[i].d_state == 1) {
          da.push(dataFile[i]);
        }
      }
      setDataFileFiltered(da);
    }
    else if (value == 3) {
      for (var i = 0; i < dataFile.length; i++) {
        if (dataFile[i].d_state == 2) {
          da.push(dataFile[i]);
        }
      }
      setDataFileFiltered(da);
    }
    else if (value == 4) {
      console.log('cas 4');
      setDataFileFiltered(dataFile);
    }
   
  }







  



  const columnsdown = [
    {
      name: 'Id',
      selector: (row) => row.Id_t_file,
      sortable: true,
      center: true,
      width: "70px"
    },
    {
      name: 'Nom',
      selector: (row) => row.d_name,
      sortable: true
    },
    {
      name: 'Date',
      selector: (row) => row.d_creation,
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={() => addFile(row)} className='btn btn-primary'><i className="fa fa-arrow-up"></i></span>


        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "100px",
      button: true
    }
  ];




  const columnsdownNew = [
    {
      name: 'Id',
      selector: (row) => row.Id_t_file,
      sortable: true,
      center: true,
      width: "70px"
    },
    {
      name: 'Nom',
      selector: (row) => row.d_name,
      sortable: true
    },
    {
      name: 'Date',
      selector: (row) => row.d_creation,
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={() => addFileNew(row)} className='btn btn-primary'><i className="fa fa-arrow-up"></i></span>


        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "100px",
      button: true
    }
  ];








  const columnsDoc = [
    {
      name: 'ID',
      selector: (row) => row.Id_t_document,
      sortable: true,
      center: true,
      width: "80px"
    },
    {
      name: 'Nom',
      selector: (row) => row.d_name,
      sortable: true,
      wrap: true,
      width: "250px"
    },
    {
      name: 'Texte',
      selector: (row) => row.d_text,
      sortable: true,
      wrap: true
    },
    {
      name: 'Date',
      selector: (row) => row.d_creation,
      sortable: true,
      center: true,
      width: "120px"
    },
    {
      name: 'Active',
      selector: (row) => row.d_visible,
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div className="btn-group btn-group-square" role="group" aria-label="Basic example">
            <span onClick={() => handleButtonClick(row)} className='btn btn-primary btn-square'><i className="fa fa-edit"></i></span>
            <span onClick={() => handleButtonDelete(row)} className='btn btn-danger btn-square'><i className="fa fa-trash "></i></span>
          </div>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "220px",
      button: true,
    },
  ];

  const handleButtonClick = (state) => {

    setdocName(state.d_name);
    setdocYear(state.d_year);
    setdocText(state.d_text);
    setCurDoc(state.Id_t_document);

    const dataFetch = async () => {
      const l = await getDocLink(state.Id_t_document);
      const f = await getRelativeFiles(state.Id_t_document);
      const t = await getTagLink(state.Id_t_document);
      const c = await getCategorie();
      const cl = await getCatLink(state.Id_t_document);




      for (var i = 0; i < c.length; i++) {
        //   console.log("ok");

        const child = await getChildCategorie(c[i].Id_t_categorie);



        for (var h = 0; h < child.length; h++) {
          child[h].isChecked = false;
        }



        for (var d = 0; d < cl.length; d++) {



          for (var h = 0; h < child.length; h++) {
            if (child[h].Id_t_categorie == cl[d].Id_t_categorie) {
              child[h].isChecked = true;
            }


          }

        }


        var dd = c[i];

        dd.catChild = child;

        c[i] = dd;

        console.log(c[i]);
      }



      setDataLink(l);
      setDataFile(f);
      setDataFileFiltered(f);
      setDataTags(t);
      setCategory(c);
      setCategoryLink(cl);
    }

    dataFetch();

    toggle2();
  };

  const handleDeleteTag = (state) => {

    var id = {
      idTag: state.Id_t_tags
    }

    console.log(state.Id_t_tags);


    const dataFetch = async () => {
      const l = await deleteTag(id);

      const t = await getTagLink(state.Id_t_document);
      setDataTags(t);
    }

    dataFetch();

  };



  useEffect(() => {

    const dataFetch = async () => {
      const d = await getDocuments();


      setDataDoc(d);

      console.log(d);
    }
    // setData(f.values);

    dataFetch();
  }, []);


  const toggle1 = () => {
    setModal1(!modal1)
  }

  const toggle2 = () => {

    setModal2(!modal2)
  }


  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);

    console.log(state.selectedRows);
    /*
        
    
        var cat = {
          idDoc: curDoc,
          idCat: state.selectedRows[0].Id_t_categorie,
        }
    
        const dataFetch = async () => {
          const d = await addDocCatLink(cat);
    
        }
        // setData(f.values);
    
        dataFetch();
    */

  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;

    // Vérifie si la touche pressée est un chiffre (0-9) ou une touche de contrôle
    if (charCode !== 0 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const contextActions = useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {

      }
    };

    return <button key="delete" onClick={handleDelete}>{"Delete"}</button>;
  }, [dataFileFiltered, selectedRows, toggleCleared]);


  const addDoc = () => {

    var newDoc = {
      name: docName,
      text: docText,
      year: docYear,
      visible: 1,
      orga: 1
    }


    const dataFetch = async () => {

      const f = await addDBDoc(newDoc, dataLink, category, dataTags);

      console.log(f);



      if (f > 0) {
        setTimeout(() => {
          toast.success("Archive ajoutée avec succès");
        }, 200);


        var doc = {
          id: f,
        }


        axios.post(adata.url + '/api/updthumb', doc)
          .then(response => {


          })
          .catch(error => {
            console.log(error);

          });


        toggle1();





      } else {
        setTimeout(() => {
          toast.error("Une erreur est survenue, l'achive n'a pas été enregistrée");
        }, 200);
      }
    }



    dataFetch();






  }

  const modDoc = () => {

    var doc = {
      id: curDoc,
      name: docName,
      text: docText,
      year: docYear,
    }




    axios.post(adata.url + '/api/updDoc', doc)
      .then(response => {

        console.log("ok");

        for (var i = 0; i < dataDoc.length; i++) {
          if (curDoc == dataDoc[i].Id_t_document) {
            dataDoc[i].d_name = docName;
            dataDoc[i].d_text = docText;
          }
        }


        axios.post(adata.url + '/api/updthumb', doc)
          .then(response => {


          })
          .catch(error => {
            console.log(error);

          });


        setTimeout(() => {
          toast.success("Archive modifiée avec succès");
        }, 200);

      })
      .catch(error => {
        console.log(error);

      });


  }

  const DrawNewDoc = () => {

    setdocName("");
    setdocYear();
    setdocText("");
    setCurDoc(0);

    const dataFetch = async () => {
      const c = await getCategorie();
      const f = await getRelativeFiles(0);

      const cv = [];



      for (var i = 0; i < c.length; i++) {
        //   console.log("ok");

        const child = await getChildCategorie(c[i].Id_t_categorie);



        for (var h = 0; h < child.length; h++) {
          child[h].isChecked = false;
        }


        var dd = c[i];

        dd.catChild = child;

        c[i] = dd;

        console.log(c[i]);
      }






      setCategory(c);
      setDataLink(cv);
      setDataTags([]);
      setDataFile(f);
      setDataFileFiltered(f);


    }

    dataFetch();

    toggle1();
  }

  const handleKeyDown = (event) => {
    const { key } = event;
    // Empêche les lettres et caractères non numériques
    if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
      event.preventDefault();
    }
  };

  const handleChangeN = (e) => {
    setdocName(e.target.value);
  };

  const handleChangeA = (e) => {
    console.log(e.target.value);

    if (/^\d*$/.test(e.target.value)) {
      console.log(e.target.value);
      setdocYear(e.target.value);
    }

  };

  const handleChangeT = (e) => {
    setdocText(e.target.value);
  };

  const handleButtonDelete = (state) => {

    if (window.confirm('Voulez-vous vraiment supprimer cette archive ?')) {

      var id = {
        idDoc: state.Id_t_document,
      }

      const f = deleteDocument(id);


      setToggleCleared(!toggleCleared);
      //  setData(differenceBy(data, state.Id_t_file, 'Id_t_file'));


      var lis = dataDoc;


      lis.splice(selectedRows, 1);

      setDataDoc(lis);


      toast.success("Document supprimé avec succes !")
    }
  };

  const addFile = (state) => {

    var link = {
      idDoc: curDoc,
      idFile: state.Id_t_file,
    }

    console.log(link);


    const dataFetch = async () => {

      const i = await addDocLink(link);
      console.log('i' + i);

      const l = await getDocLink(curDoc);
      const f = await getRelativeFiles(curDoc);

      setDataLink(l);
      setDataFile(f);
      updateTable(radioValue);


    };

    dataFetch();

  }

  const addFileNew = (state) => {

    console.log(state.Id_t_file);

    var l = dataLink;
    var f = dataFile;






    const index = dataFile.findIndex(obj => obj.Id_t_file === state.Id_t_file);

    console.log(index);

    l.push(dataFile[index]);
    f.splice(index, 1);


    setDataLink(l);
    setDataFile(f);

    updateTable(radioValue);


    setForceRender(!forceRender);




  };





  const removeFile = (state) => {

    var link = {
      idDoc: curDoc,
      idFile: state.Id_t_file,
    }

    console.log(link);


    const dataFetch = async () => {

      const i = await removeDocLink(link);
      console.log('i' + i);

      const l = await getDocLink(curDoc);
      const f = await getRelativeFiles(curDoc);

      setDataLink(l);
      setDataFile(f);

    };

    dataFetch();

  }

  const removeFileNew = (state) => {

    var l = dataLink;
    var f = dataFile;







    const index = dataLink.findIndex(obj => obj.Id_t_file === state.Id_t_file);

    console.log(index);

    f.push(dataLink[index]);
    l.splice(index, 1);



    setDataLink(l);
    setDataFile(f);
    setDataFileFiltered(f);


    setForceRender(!forceRender);




  }

  const addTag = () => {

    var tags = {
      name: tag,
    }



    const dataFetch = async () => {

      const i = await addDBTag(tags);
      console.log('i' + i);


      var t = {
        idDoc: curDoc,
        idTag: i
      }

      const l = await addDocTagLink(t);

      const ta = await getTagLink(curDoc);

      //setDataLink(l);
      //setDataFile(f);
      setDataTags(ta);

    };

    dataFetch();




  };


  const addTagN = () => {


    console.log(tag);



    var to = dataTags.slice();


    var vtag = {
      d_name: tag
    }

    to.push(vtag);

    console.log(to);


    setDataTags(to);
    setTag('');


  };

  const handleChangeTag = (e) => {
    setTag(e.target.value);
  };

  const handleSearchTag = (e) => {
   
    const dataFetch = async () => {
    const f = await searchRelativeFile(curDoc,searchText);

    setDataFileFiltered(f);

    }

    dataFetch();

  };


  const handleChangeTagN = (e) => {
    setTag(e.target.value);
  };

  const handleChangeS = (e) => {
    setSearchText(e.target.value);
  };


  const tagCol = [
    {
      name: 'Tag',
      selector: (row) => row.d_name,
      sortable: true,
      center: true,
      compact: true,
      grow: 1
    },
    {
      name: "",
      cell: (row) => (
        <>
          <span onClick={() => handleDeleteTag(row)} className='btn btn-danger'><i className="fa fa-trash-o"></i></span>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      width: "100px",
      button: true,
      compact: true
    }
  ];

  const catCol = [
    {
      name: 'Catégorie',
      selector: (row) => row.d_id_name,
      sortable: true,

    }

  ];

  const onAddingItem = (i, d) => (event) => {

    var c = category.slice();

    console.log(c[i].catChild[d].isChecked);

    c[i].catChild[d].isChecked = !c[i].catChild[d].isChecked;

    console.log(c[i].catChild[d].isChecked);

    setCategory(c);

    var cat = {
      idDoc: curDoc,
      idCat: c[i].catChild[d].Id_t_categorie
    }


    const dataFetch = async () => {
      const l = await addDocCatLink(cat);


    };

    const dataFetchD = async () => {
      const l = await deleteCatLink(cat);


    };

    if (c[i].catChild[d].isChecked) {
      dataFetch();
    }
    else {
      dataFetchD();
    }
  }


  


  const onAddingItemN = (i, d) => (event) => {

    var c = category.slice();

    c[i].catChild[d].isChecked = !c[i].catChild[d].isChecked;


    setCategory(c);


    var cat = {
      idDoc: curDoc,
      idCat: c[i].catChild[d].Id_t_categorie
    }

    /*
        const dataFetch = async () => {
          const l = await addDocCatLink(cat);
    
    
        };
    
        const dataFetchD = async () => {
          const l = await deleteCatLink(cat);
    
    
        };
    
        if (c[i].isChecked) {
          dataFetch();
        }
        else {
          dataFetchD();
        }
        */
  }






  return (
    <Fragment>
      <Breadcrumb title="Archives" parent="Commune de Braives" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">

              <div className="card-body datatable-react">

                <div className="btn-showcase">

                  <button className="btn btn-square btn-success float-end" type="button" onClick={DrawNewDoc}>Nouveau <i className="fa fa-plus"></i></button>
                </div>

                <DataTable
                  columns={columnsDoc}
                  data={dataDoc}
                  striped={true}
                  center={true}
                  persistTableHead


                  clearSelectedRows={toggleCleared}
                />
                <Modal isOpen={modal1} toggle={toggle1}>
                  <ModalHeader toggle={toggle1}>Nouvelle archive</ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">

                            <div className="card-body">


                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Nom</label>
                                    <input className="form-control" value={docName} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeN} />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Année</label>
                                    <input className="form-control" value={docYear} id="exampleFormControlInput2" type="number" onKeyDown={handleKeyDown} placeholder="" onChange={handleChangeA} />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputPassword2">Texte</label>
                                    <textarea value={docText} className="form-control" id="exampleFormControlTextarea4" rows="6" onChange={handleChangeT}></textarea>
                                  </div>
                                </div>
                              </div>
                              




                              <div className="row">
                                <div className="col-sm-7">
                                  {

                                    category.map((cat, i) => (
                                      <div key={i}>
                                        <h4>{cat.d_id_name}</h4>

                                        {
                                          cat.catChild.map((child, d) => (
                                            <label className="d-block" htmlFor={"chk-ani" + d} key={d}>
                                              <input className="checkbox_animated" key={d} id={"chk-ani" + d} type="checkbox" value={child.Id_t_categorie} checked={child.isChecked} onChange={onAddingItemN(i, d)} />
                                              {child.d_id_name}
                                            </label>
                                          ))
                                        }
                                      </div>
                                    ))

                                  }


                                </div>


                                <div className="col-sm-5">
                                  <DataTable
                                    columns={tagCol}
                                    data={dataTags}
                                    striped={true}
                                    center={true}
                                    pagination={false}

                                    persistTableHead
                                    contextActions={contextActions}
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleCleared}
                                    dense
                                  />

                                  <div className="row">
                                    <div className="col-sm-8">
                                      <input className="form-control" value={tag} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeTagN} />
                                    </div>
                                    <div className="col-sm-4">
                                      <button className="btn btn-square btn-success float-end" type="button" onClick={addTagN}><i className="fa fa-plus-square"></i></button>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>


                          <div className="card">
                            <div className="card-header">
                              <h5>Documents liés</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <DataTable
                                  columns={columnsupNew}
                                  data={dataLink}
                                  striped={true}
                                  center={true}
                                  persistTableHead
                                  contextActions={contextActions}
                                  onSelectedRowsChange={handleRowSelected}
                                  clearSelectedRows={toggleCleared}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="card">
                          <div className="card-header">
                              <div className="row">
                                <div className="col-sm-5">
                                  <h5>Documents</h5>
                                </div>
                                <div className="col-sm-7">
                                  <div className="row">
                                    <div className="col-sm-9">
                                      <input className="form-control" value={searchText} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeS} />
                                    </div>
                                    <div className="col-sm-2">
                                      <span onClick={() => handleSearchTag()} className='btn btn-info'><i className="fa fa-search"></i></span>
                                    </div>
                                  </div>
                                </div>
                              </div>



                              <div className="row">
                                <p></p>
                                <div className="col-sm-2">
                                </div>
                                <div className="col-sm-8">
                                  <ButtonGroup>
                                    {radios.map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                                        name="radio"
                                        value={radio.value}
                                        checked={radioValue === radio.value}

                                        onChange={(e) => updateTable(e.currentTarget.value)}
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </div>

                                <div className="col-sm-2">
                                </div>
                              </div>

                            </div>
                            <div className="card-body">
                              <div className="row">
                                <DataTable
                                  columns={columnsdownNew}
                                  data={dataFileFiltered}
                                  striped={true}
                                  center={true}
                                  persistTableHead
                                  contextActions={contextActions}
                                  onSelectedRowsChange={handleRowSelected}
                                  clearSelectedRows={toggleCleared}
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={addDoc}>Enregistrer</Button>{' '}
                    <Button color="secondary" onClick={toggle1}>Annuler</Button>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modal2} toggle={toggle2} size="lg">
                  <ModalHeader toggle={toggle2}>Modifier archive {curDoc}</ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">

                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Nom</label>
                                    <input className="form-control" value={docName} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeN} />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Année</label>
                                    <input className="form-control" value={docYear} id="exampleFormControlInput2" type="number" onKeyDown={handleKeyDown} placeholder="" onChange={handleChangeA} />
                                  </div>
                                </div>
                              </div>



                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputPassword2">Texte</label>
                                    <textarea value={docText} className="form-control" id="exampleFormControlTextarea4" rows="6" onChange={handleChangeT}></textarea>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-7">

                                  {

                                    category.map((cat, i) => (
                                      <div key={i}>
                                        <h4>{cat.d_id_name}</h4>

                                        {
                                          cat.catChild.map((child, d) => (
                                            <label className="d-block" htmlFor={"chk-ani" + d} key={d}>
                                              <input className="checkbox_animated" key={d} id={"chk-ani" + d} type="checkbox" value={child.Id_t_categorie} checked={child.isChecked} onChange={onAddingItem(i, d)} />
                                              {child.d_id_name}
                                            </label>
                                          ))
                                        }
                                      </div>
                                    ))

                                  }

                                </div>


                                <div className="col-sm-5">
                                  <DataTable
                                    columns={tagCol}
                                    data={dataTags}
                                    striped={true}
                                    center={true}
                                    pagination={false}

                                    persistTableHead
                                    contextActions={contextActions}
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleCleared}
                                    dense={true}
                                  />

                                  <div className="row">
                                    <div className="col-sm-8">
                                      <input className="form-control" value={tag} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeTag} />
                                    </div>
                                    <div className="col-sm-4">
                                      <button className="btn btn-success float-end" type="button" onClick={addTag}><i className="fa fa-plus-square"></i></button>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>


                          <div className="card">
                            <div className="card-header">
                              <div className="row">
                                <div className="col-sm-12">
                                  <h5>Documents liés</h5>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <DataTable
                                  columns={columnsup}
                                  data={dataLink}
                                  striped={true}
                                  center={true}
                                  persistTableHead
                                  contextActions={contextActions}
                                  onSelectedRowsChange={handleRowSelected}
                                  clearSelectedRows={toggleCleared}
                                />
                              </div>
                            </div>


                          </div>




                          <div className="card">
                            <div className="card-header">
                              <div className="row">
                                <div className="col-sm-5">
                                  <h5>Documents</h5>
                                </div>
                                <div className="col-sm-7">
                                  <div className="row">
                                    <div className="col-sm-9">
                                      <input className="form-control" value={searchText} id="exampleFormControlInput1" type="text" placeholder="" onChange={handleChangeS} />
                                    </div>
                                    <div className="col-sm-2">
                                      <span onClick={() => handleSearchTag()} className='btn btn-info'><i className="fa fa-search"></i></span>
                                    </div>
                                  </div>
                                </div>
                              </div>



                              <div className="row">
                                <p></p>
                                <div className="col-sm-2">
                                </div>
                                <div className="col-sm-8">
                                  <ButtonGroup>
                                    {radios.map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                                        name="radio"
                                        value={radio.value}
                                        checked={radioValue === radio.value}

                                        onChange={(e) => updateTable(e.currentTarget.value)}
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </div>

                                <div className="col-sm-2">
                                </div>
                              </div>

                            </div>

                            <div className="card-body">
                              <div className="row">
                                <DataTable
                                  columns={columnsdown}
                                  data={dataFileFiltered}
                                  striped={true}
                                  center={true}
                                  persistTableHead
                                  contextActions={contextActions}
                                  onSelectedRowsChange={handleRowSelected}
                                  clearSelectedRows={toggleCleared}
                                />


                              </div>




                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={modDoc}>Enregistrer</Button>{' '}
                    <Button color="secondary" onClick={toggle2}>Annuler</Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Documents;